/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import type { ContentServiceV1 } from '@oneaudi/content-service';
import { RenderModeServiceV1 } from '@oneaudi/render-mode-service';
import { Logger } from '@feature-hub/core';
import { UniversalEditorProvider } from '@oneaudi/falcon-tools';
import { StoryStream } from './component/story-stream';

interface FeatureAppProps {
  contentService: ContentServiceV1;
  renderModeService?: RenderModeServiceV1;
  featureAppId: string;
  loggerServices: Logger;
}

const FeatureApp: React.FC<FeatureAppProps> = ({
  contentService,
  renderModeService,
  featureAppId,
  loggerServices,
}: FeatureAppProps) => {
  return (
    <UniversalEditorProvider contentService={contentService} renderModeService={renderModeService}>
      <StoryStream
        logger={loggerServices}
        contentService={contentService}
        featureAppId={featureAppId}
      />
    </UniversalEditorProvider>
  );
};

export default FeatureApp;
