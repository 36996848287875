import * as React from 'react';
import { useEffect, useState } from 'react';
import { ContentService, Logger } from '../../@types/feature-service-types';
import { CmsContent, normalizeContent } from '../editor';

export interface StoryStreamProps {
  featureAppId: string;
  logger: Logger;
  contentService: ContentService;
}

export const StoryStream: React.FC<StoryStreamProps> = ({
  featureAppId,
  logger,
  contentService,
}) => {
  const [editorContent, setEditorContent] = React.useState<ReturnType<typeof normalizeContent>>(
    normalizeContent(contentService.getContent() as CmsContent),
  );

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  // const editorContent = contentService.getContent();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
  const [appId, setAppId] = useState<string | undefined>(editorContent?.storyStreamClientId);
  const [elementId, setElementId] = useState<string>(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
    editorContent?.storyStreamElementId || 'stry-wrapper',
  );

  // This is done to dynamically update the app based on configuration changes
  // and is more or less only done in the demo
  useEffect(() => {
    contentService.onContentChange(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const newEditorContent = contentService.getContent();
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      setAppId(newEditorContent?.storyStreamClientId);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
      setElementId(newEditorContent?.storyStreamElementId || 'stry-wrapper');
    });
  }, [contentService]);

  useEffect(() => {
    const listener = () => {
      setEditorContent(normalizeContent(contentService.getContent() as CmsContent));
    };
    contentService.onContentChange(listener);

    return () => {
      contentService.removeOnContentChange(listener);
    };
  }, [contentService, setEditorContent]);

  useEffect(() => {
    const foundScriptTags = document.querySelectorAll(
      `script[data-featureappid*="${featureAppId}"]`,
    );

    if (foundScriptTags.length > 0) {
      logger.info(`StoryStream script already added for ${featureAppId}`);
      return () => {
        // nothingTodo
      };
    }

    if (!appId || appId.length === 0) {
      logger.error(`The StoryStream Client/App ID for ${featureAppId} is undefined or empty`);
      return () => {
        // nothingTodo
      };
    }

    const script = document.createElement('script');

    script.dataset.featureapp = featureAppId;
    script.src = `https://apps.storystream.ai/app/js/${appId}.js`;
    script.async = true;

    document.body.appendChild(script);
    logger.info(`Added StoryStream script for ${featureAppId}`, script);

    return () => {
      document.body.removeChild(script);
      logger.info(`Removed StoryStream script for ${featureAppId}`);
    };
  }, [appId]);

  return <div id={elementId} className="digital-magazine" />;
};
