import { ContentFragment, InvalidContentError, isContentFragment } from '@oneaudi/falcon-tools';

export interface FalconContent extends ContentFragment {
  __type: 'aem-headless';
  fields: {
    storyStreamClientId: string;
    storyStreamElementId: string;
  };
}

export interface Content {
  __type?: 'editor.json';
  storyStreamClientId: string;
  storyStreamElementId: string;
}

function isFalconContent(content: unknown): content is FalconContent {
  return Boolean(
    isContentFragment(content) &&
      'storyStreamClientId' in content.fields &&
      content.fields.storyStreamClientId &&
      'storyStreamElementId' in content.fields,
  );
}

export type CmsContent = Content | FalconContent;

export function normalizeContent(content: CmsContent): Content {
  if (content?.__type !== 'aem-headless') {
    return content;
  }

  if (!isFalconContent(content)) {
    throw new InvalidContentError('Social stream content is invalid.');
  }

  return {
    storyStreamClientId: content.fields.storyStreamClientId,
    storyStreamElementId: content.fields.storyStreamElementId,
  };
}
